const initialState = {
  isAuthenticated: false,
  appLoaded: false,
  profile: {},
  token: null,
};

function authReducer(state, action = {}) {
  const { type, token, profile, user } = action;

  switch (type) {
    case 'logout':
      return { ...initialState };
    case 'login':
      const _state = { ...state };
      delete _state.user;
      return { ..._state, isAuthenticated: true, token };
    case 'createPassword':
      return { ...state, user };
    case 'setProfile':
      return { ...state, appLoaded: true, profile };
    case 'syncProfile':
      return state;
    default:
      return { ...state };
  }
}

export { initialState, authReducer };
