import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import createInterceptor from '../../lib/helpers/interceptor';
import fetchMe from '../../lib/fetch/me';
import { initialState, authReducer } from './authReducer';

const AuthContext = createContext([{}, () => {}]);

const AuthProvider = ({ children }) => {
  const { t } = useTranslation('LOGIN');
  const alert = useAlert();

  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const { token } = cookies;
  const isAuthenticated = !!(token && token.length > 0);
  // const isAuthenticated = true;
  const [state, dispatch] = useReducer(authReducer, {
    ...initialState,
    token,
    isAuthenticated,
  });

  const dispatchFunction = (action = {}) => {
    const { type, token } = action;
    switch (type) {
      case 'login':
        setCookie('token', token, {
          maxAge: 3600,
          path: '/',
        });
        break;
      case 'logout':
        removeCookie('token');
        break;
      case 'syncProfile':
        syncProfile();
        break;
      default:
        break;
    }
    return dispatch(action);
  };

  useEffect(() => {
    createInterceptor(401, () => dispatch({ type: 'logout' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const syncProfile = async () => {
    const { error, data } = await fetchMe(token);
    if (error) {
      dispatchFunction({ type: 'logout' });
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    dispatch({ type: 'setProfile', profile: data });
  };

  useEffect(() => {
    if (isAuthenticated) syncProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <AuthContext.Provider value={[state, dispatchFunction]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthContext, useAuth };
